<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-row class="justify-center mx-0">
    <v-col md="8">
      <v-row class="justify-center">
        <v-card
            tile
            class="pa-5 col-sm-7 mt-10"
        >
          <div class="warning py-5 px-3 mb-2" v-if="errors">
            {{ errors }}
          </div>
          <div class="py-5 px-3 mb-2" v-if="message">
            {{ message }}
          </div>
          <span v-if="!success">Enter your phone number</span>

          <validation-observer
              ref="observer"
          >
            <form @submit.prevent="resetPassword" class="text-center" v-if="!success">
              <validation-provider
                  v-slot="{ errors }"
                  name="phone"
                  :rules="{ required: true, digits: 11, regex: '^(01[3-9])\\d{8}$'}"
              >
                <v-text-field
                    name="phone"
                    v-model="phone"
                    :counter="11"
                    :error-messages="errors"
                    label="Phone Number"
                    required
                ></v-text-field>
              </validation-provider>

              <v-btn
                  class="mr-4"
                  type="submit"
                  :disabled="!isValidForm || busy"
                  :loading="busy"
                  rounded
                  color="blue"
                  min-width="300"
              >
                Submit
                <template v-slot:loader>
                  <span>Loading..</span>
                </template>
              </v-btn>
            </form>
          </validation-observer>
          <div class="text-center mt-4">
            <v-btn @click="$router.push({name:'Login'})" text small class="text-center">Go to login page</v-btn>
          </div>
        </v-card>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import {mapGetters} from 'vuex'
import {required, digits, max, min, regex} from 'vee-validate/dist/rules'
import {extend, ValidationObserver, ValidationProvider, setInteractionMode} from 'vee-validate'
import axios from "axios";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    name: '',
    phone: '',
    message: '',
    success: false,
    show: false,
    errors: '',
    busy: false,
    wait: 300,
  }),

  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
    resend() {
      return this.step === 'login' && this.wait < 1
    },
    form() {
      let formData = new FormData();
      formData.append('phone', this.phone)
      return formData;
    },
    isValidPhone() {
      let re = /(01)[3-9]\d{8}/;
      return re.test(this.phone);
    },
    isValidForm() {
      return (this.isValidPhone)
    }
  },
  methods: {
    resetPassword() {
      this.busy = true
      const url = 'login/reset'
      this.wait = 300
      axios.post(url, this.form).then(() => {
        this.message = 'A new password has been sent to your phone number. Please go to login page and use it as your password.'
        this.success = true
      }).catch(() => {

      })
    },
  },
  mounted() {
    setInterval(() => {
      this.wait--;
    }, 1000);
  },
}

setInteractionMode('eager');

extend('digits', {
  ...digits,
  message: '{_field_} needs to be {length} digits.',
})

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters',
})

extend('regex', {
  ...regex,
  message: '{_field_} format is not valid',
})

extend('min', {
  ...min,
  message: 'Minimum 6 characters',
})
</script>